import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import ImageComponent from '../GeneralComponents/ImageComponent'
import generalCss from '../../styles/generalCss'

export default function TechnologyCards({
    cardList = [],
    smWidth = 2,
    xsWidth = 12
}) {
    return (
        <div>
            <Grid container spacing={5} alignItems='center' justifyContent='center'>
                {
                    cardList.map(card => {
                        return <Grid item xs={xsWidth} sm={smWidth} key={card.id}>
                            <Grid container spacing={4} direction='column' alignItems='center'>
                                <Grid item>
                                    {
                                        !!card.image ?
                                            <ImageComponent
                                                src={card.image.url}
                                                alt={card.image.alternativeText}
                                            /> :
                                            <div
                                                style={{
                                                    backgroundColor: generalCss.primaryFadedBgColor,
                                                    height: "60px",
                                                    width: "60px",
                                                    borderRadius: '50%'
                                                }}
                                            ></div>
                                    }

                                </Grid>

                                <Grid item>
                                    <Typography variant='body1' style={{ fontWeight: "700", textAlign: 'center' }} color='textPrimary'>{card.label}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    })
                }
            </Grid>
        </div>
    )
}
