import React from 'react'

export default function RightArrow() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.5" cy="12.126" r="12" fill="#2A6AE2" />
            <path d="M10.0303 15.186L13.0836 12.126L10.0303 9.06598L10.9703 8.12598L14.9703 12.126L10.9703 16.126L10.0303 15.186Z" fill="white" />
        </svg>

    )
}
