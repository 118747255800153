import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

export const getBannerHtml = ({ heading, para, buttonTitle, buttonHref, bannerId }) => {
    return `
    <section id=${bannerId}>
    <div class="apply">
      <div class="container">
        <h2 style="color:white !important">${heading}</h2>
        <p style="color:white !important">${para}</p>
        <div class="applybtn">
        <a href="${buttonHref}">
          <button>${buttonTitle}</button>
        </a>
      </div>
      </div>
    </div>
    </section>`;
};

const useStyles = makeStyles(theme => ({
    root: {
        "& *": {
            color: theme.palette.text.primary,
            fontSize: '16px',
            lineHeight: "24px",
            marginTop: '0px',
            [theme.breakpoints.up('sm')]: {
                fontSize: '18px',
                lineHeight: "30px",
            }
        },
        // '& p': {
        //     margin: '0px',
        //     marginBottom: "20px"
        // },
        '& h2>span': {
            fontSize: '1.25rem',
            fontWeight: '600',
            lineHeight: "2rem",
            [theme.breakpoints.up('sm')]: {
                fontSize: '2rem',
                fontWeight: 'bold',
                lineHeight: "2.8rem"
            }
        },
        '& h3>span': {
            fontSize: '1.25rem',
            fontWeight: '600',
            lineHeight: "2rem",
            [theme.breakpoints.up('sm')]: {
                fontSize: '2rem',
                fontWeight: '600',
                lineHeight: "2.8rem",
            }
        },
        '& h4>span': {
            fontSize: '1.5rem',
            fontWeight: '600',
            lineHeight: "2rem",
            [theme.breakpoints.up('sm')]: {
                fontSize: '2rem',
                fontWeight: '600',
                lineHeight: "2.8rem",
            }
        },
        '& h5>span': {
            fontSize: '1.125rem',
            fontWeight: '600',
            lineHeight: "1.8rem",
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.25rem',
                fontWeight: '600',
                lineHeight: "2rem",
            }
        },
        '& h6>span': {
            fontSize: '1.125rem',
            fontWeight: '600',
            lineHeight: "1.8rem",
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.25rem',
                fontWeight: '600',
                lineHeight: "2rem",
            }
        }
    }
}))

export default function RichTextComponent({ text, textStyles = '', textClassName = '', className, bannerHref }) {
    const classes = useStyles()
    const bannerHtml = getBannerHtml({
        heading: "Launch your product with us",
        para: "We had the pleasure of being a partner for companies from markets like ....",
        buttonTitle: "Enquire",
        buttonHref: bannerHref,
        bannerId: "banner1"
    })
    const bannerSplittedContent = text.split(/BANNER/g)
    let contentWithAllBanners = ''
    for (let [index, content] of bannerSplittedContent.entries()) {
        contentWithAllBanners += `<div style="${textStyles}" class="${textClassName}">${content}</div>`
        if (bannerSplittedContent.length > 1 && index < bannerSplittedContent.length - 1) {
            contentWithAllBanners += bannerHtml
        }
    }

    return (
        <div className={clsx(classes.root, className)} dangerouslySetInnerHTML={{ __html: contentWithAllBanners }} />
    )
}




    //     <style>
    //     .apply {

    //     }
    //     .apply h2 {
    //       font-weight: bold;
    //       font-size: 36px;
    //       line-height: 44px;
    //       text-align: center;
    //     }
    //     .apply p {
    //       text-align: center;
    //       font-weight: 500;
    //       font-size: 16px;
    //       line-height: 24px;
    //     }
    //   //   .apply .applybtn {
    //   //     text-align: center;
    //   //     margin: 30px auto;
    //   //   }
    //   //   .apply button {
    //   //     width: 169px;
    //   //     height: 32px;
    //   //     border: none;
    //   //     color: white;
    //   //     background: #00cf75;
    //   //     box-shadow: 0px 4px 20px rgba(6, 99, 58, 0.5);
    //   //     border-radius: 12px;
    //   //     cursor: pointer;
    //   //     text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    //   //     font-weight: bold;
    //   //   }
    //   //   .apply button a {
    //   //     color: white;
    //   //   }

    //   //   .apply button:hover {
    //   //     background: #16b671;
    //   //   }
    //     @media (max-width: 600px) {
    //       .apply {
    //         padding:20px;
    //      }
    //       .apply h2 {
    //         margin-bottom: 20px;
    //         font-size: 40px;
    //         line-height: 48px;
    //      }
    //       .apply p {
    //         font-size: 20px;
    //         line-height: 24px;
    //      }
    //     }
    //     </style>