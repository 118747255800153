import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import ImageComponent from '../GeneralComponents/ImageComponent'
import LinkButton from '../GeneralComponents/Buttons/LinkButton'
import Routes from '../../utilities/Routes'
import RightArrowIcon from '@material-ui/icons/ArrowForward'
import LinkComponent from '../GatsbyComponents/LinkComponent'
import TagComponent from '../GeneralComponents/TagComponent'
const useStyles = makeStyles(theme => ({
    container: {
        borderRadius: "24px",
        // textAlign: 'center',
        height: "100%",
        display: 'flex'
    },
    imageStyles: {
        width: '100%',
        height: '200px',
        maxHeight: "250px",
        borderRadius: "12px 12px 0px 0px",
        objectFit: 'cover'
    },
    buttonStyles: {
        minWidth: "180px"
    },
    buttonContainer: {
        width: "100%"
    },
    title: {
        fontSize: '1.3rem',
        lineHeight: "1.8rem",
        fontWeight: '600',
        [theme.breakpoints.up("sm")]: {
            fontSize: '1.5rem',
            lineHeight: "2.3rem",
        }
    }
}))

function CaseStudyCardNonFeatured({ data }) {
    const { title, excerpt, thumbNailImage, services, slug } = data
    const classes = useStyles()
    return (
        <div id={data.id} className={classes.container}>
            <Grid container direction='column' >
                <Grid item>
                    <ImageComponent
                        src={!!thumbNailImage ? thumbNailImage.url : ""}
                        alt={!!thumbNailImage ? thumbNailImage.alternativeText : ""}
                        className={classes.imageStyles}
                    />
                </Grid>
                <Grid item style={{ flex: 1, display: 'flex' }} >
                    <div style={{ paddingTop: '15px', display: 'flex', flex: 1 }}>
                        <Grid container direction='column' justifyContent='space-between' spacing={2}>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {
                                            !!slug ?
                                                <LinkComponent path={`${Routes.caseStudies}/${slug}`}>
                                                    <Typography variant='h6' component='h5' color='primary' >{title}</Typography>
                                                </LinkComponent>
                                                :
                                                <Typography variant='h6' component='h5' color='primary' >{title}</Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body1' color='textPrimary' style={{ whiteSpace: 'pre-line' }} >{excerpt}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TagComponent array={services.split(',')} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                !!slug &&
                                <Grid item>
                                    <div style={{ display: 'flex' }}
                                    >
                                        <LinkButton className={classes.buttonStyles}
                                            href={`${Routes.caseStudies}/${slug}`}
                                        >
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    Read More
                                                </Grid>
                                                <Grid item style={{ display: 'flex' }}>
                                                    <RightArrowIcon color='primary' />
                                                </Grid>
                                            </Grid>
                                        </LinkButton>
                                    </div>

                                </Grid>
                            }
                        </Grid>

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}


export default CaseStudyCardNonFeatured