import React from 'react'
import { Grid, Typography, Hidden, makeStyles } from '@material-ui/core'
import ImageComponent from '../GeneralComponents/ImageComponent'
import LinkButton from '../GeneralComponents/Buttons/LinkButton'
import RightArrowIcon from '@material-ui/icons/ArrowForward'
import Routes from '../../utilities/Routes'
import LinkComponent from '../GatsbyComponents/LinkComponent'
import TagComponent from '../GeneralComponents/TagComponent'

const useStyles = makeStyles(theme => ({
    imageStyles: {
        width: '100%',
        maxHeight: "200px",
        borderRadius: '12px',
        objectFit: 'cover',
        [theme.breakpoints.up('sm')]: {
            borderRadius: "12px",
            maxHeight: "400px",
        }
    },
    buttonStyles: {
        minWidth: "100%",
        [theme.breakpoints.up("md")]: {
            minWidth: "180px"
        }
    },
    buttonContainer: {
        width: '50%',
        [theme.breakpoints.up("md")]: {
            width: "auto"
        }
    },
    title: {
        fontSize: '1.125rem',
        lineHeight: "1.8rem",
        fontWeight: '600',
        [theme.breakpoints.up("sm")]: {
            fontSize: '1.5rem',
            lineHeight: "2.3rem",
        }
    }
}))

function CaseStudyCard({ data, flexDirection = 'row' }) {
    const { title, excerpt, thumbNailImage, services, slug } = data
    const classes = useStyles()
    return <Grid container alignItems='stretch' spacing={3} direction={flexDirection}>
        <Grid item xs={12} md={5}>
            <ImageComponent
                src={!!thumbNailImage ? thumbNailImage.url : ""}
                alt={!!thumbNailImage ? thumbNailImage.alternativeText : ""}
                className={classes.imageStyles}
            />
        </Grid>
        <Grid item xs={12} md={7}>
            <Grid container spacing={2} >
                {/* <Grid item>
                    <Grid container spacing={2}>
                       
                    </Grid>
                </Grid> */}
                <Grid item xs={12}>
                    {
                        !!slug ?
                            <LinkComponent path={`${Routes.caseStudies}/${slug}`}>
                                <Typography component='h3' color='textPrimary' className={classes.title} >{title}</Typography>
                            </LinkComponent>
                            :
                            <Typography component='h3' color='textPrimary' className={classes.title} >{title}</Typography>
                    }

                </Grid>
                <Grid item xs={12}>
                    <TagComponent array={services.split(',')} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1' color='textPrimary' style={{ whiteSpace: 'pre-line' }} >{excerpt}</Typography>
                </Grid>
                {
                    !!slug &&
                    <Grid item xs={12}>
                        <LinkButton className={classes.buttonStyles}
                            href={`${Routes.caseStudies}/${slug}`}
                        >
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    Read More
                                </Grid>
                                <Grid item style={{ display: 'flex' }}>
                                    <RightArrowIcon color='primary' />
                                </Grid>
                            </Grid>
                        </LinkButton>
                    </Grid>
                }

            </Grid>

        </Grid>
    </Grid>
}

function CaseStudyCardFeatured({ data, index, width }) {
    return (
        <div id={data.id}>
            <Hidden mdUp implementation='css'>
                <CaseStudyCard data={data} flexDirection={'row'} />
            </Hidden>
            <Hidden smDown implementation='css'>
                <CaseStudyCard data={data} flexDirection={index % 2 === 0 ? 'row' : 'row-reverse'} />
            </Hidden>
        </div>
    )
}


export default CaseStudyCardFeatured