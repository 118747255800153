import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { returnTitleSubHeadingsAndImages } from '../utilities/helperFunctions'
import Routes from '../utilities/Routes'
import BreadCrumbComponent from '../components/BreadCrumbComponent'
import RichTextComponent from '../components/RichTextComponent'
import { makeStyles } from '@material-ui/core'
import SectionTitleComponent from '../components/SectionTitleComponent'
import TechnologyCards from '../components/TechnologyCards'
import CaseStudyCards from '../components/CaseStudyCards'
import generalCss from '../styles/generalCss'
import { contactFormId } from '../utilities/Configurations'

const useStyles = makeStyles(theme => ({
    container: {
        padding: "20px",
        [theme.breakpoints.up("sm")]: {
            padding: "2rem 2rem 0px"
        }
    },
    title: {
        fontSize: '1.3rem',
        lineHeight: "1.8rem",
        fontWeight: '600',
        [theme.breakpoints.up("sm")]: {
            fontSize: '1.5rem',
            lineHeight: "2.3rem",
        }
    }
}))

export default function CaseStudyDetails(props) {
    const classes = useStyles()
    const data = props.pageContext.data
    const heroData = returnTitleSubHeadingsAndImages(data)
    const technologyList = !!data.technologiesUsed ? data.technologiesUsed.map(item => ({ id: item.id, label: item.technologyName, image: item.technologyImage })) : []
    const relatedCaseStudies = !!data.brs_case_studies ? data.brs_case_studies : []
    const breadCrumbs = [
        { id: 'home', label: "Home", route: Routes.home },
        { id: "case-studies", label: "Case Studies", route: Routes.caseStudies },
        { id: "case-study-detail", label: heroData.title }
    ]
    const SEOData = !!data.SEO && data.SEO.length !== 0 ? data.SEO[0] : {}
    return (
        <div>
            <Layout pageSeo={SEOData}>
                <Hero
                    title={heroData.title}
                    titleVariant='h5'
                    titleClassName={classes.title}
                    subHeadings={[{ id: 'sub-title', value: heroData.subTitle }]}
                    coverImageMobile={heroData.coverImage}
                    coverImageWeb={heroData.coverImage}
                    showButton={false}
                />
                <BreadCrumbComponent crumbsList={breadCrumbs} />
                <div className='mainContainer'>
                    {
                        !!data.details &&
                        <section id="details">
                            {/* <div className={classes.container}>
                                
                            </div> */}
                            <RichTextComponent text={!!data.details ? data.details : ''}
                                textClassName={classes.container}
                                bannerHref={`${Routes.caseStudies}/${data.slug}/#${contactFormId}`}
                            />
                        </section>
                    }
                </div>
                {
                    technologyList.length !== 0 &&
                    <SectionTitleComponent
                        sectionId="technologies"
                        title="Technologies"
                        coloredTitle="Used"
                        reverse={true}
                    >
                        <TechnologyCards
                            cardList={technologyList}
                        />
                    </SectionTitleComponent>
                }

                {
                    relatedCaseStudies.length !== 0 &&
                    <SectionTitleComponent
                        sectionId='related-case-studies'
                        title='Related'
                        backgroundColor={generalCss.sectionBlueBg}
                        coloredTitle="Case Studies"
                    // subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                    >
                        <CaseStudyCards
                            cardList={relatedCaseStudies.slice(0, 3)}
                            showViewAllButton={true}
                            featured={false}
                        />
                    </SectionTitleComponent>
                }
            </Layout >
        </div >
    )
}
